import React, { useState } from "react";
import {BsChevronDown, BsChevronUp} from 'react-icons/bs'

function splitArray(arr, chunkSize) {
  let result = [];

  for (let i = 0; i < arr.length; i += chunkSize) {
    let chunk = arr.slice(i, i + chunkSize);
    result.push(chunk);
  }

  return result;
}

const BaseFaq = ({ q, a }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="base_faq_wrapper" onClick={() => setIsOpen(!isOpen)}>
      <div className="question"><p>{q}</p> {isOpen?<BsChevronUp/>:<BsChevronDown/>}</div>
      <div className="answer" style={isOpen ? { maxHeight: '400px', marginBottom: '1rem' } : null}>
        {a}
      </div>
    </div>
  );
};

const BaseFaqContainer = ({ faqs }) => {
  const faqsInColumns = splitArray(faqs, 2);
  return (
    <section className="base_faq_container_wrapper">
      <h2>FAQs</h2>
      <div className="base_faq_container">
        {faqsInColumns.map((faqs, index) => {
          return (
            <div key={index}>
              {faqs.map(({ question, answer, id }) => {
                return <BaseFaq q={question} a={answer} key={id} />;
              })}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default BaseFaqContainer;
