import Benefits from "./Benefits";
import ContactForm from "./ContactForm";
import Hero from "./Hero";
import PostHero from "./PostHero";
import Services from "./Services";
import Warranty from "./Warranty";
import { useLanguage } from "../../context/LanguageProvider";


const Home = () => {
  const { language, texts } = useLanguage(); // Usamos useLanguage

  return (
    <main className="main">
      <Hero title={texts[language].titleHome}
        text={texts[language].subtitleHome} />
      <PostHero />
      <Services />
      <Benefits />
      <Warranty />
      <ContactForm />
    </main>
  );
};

export default Home;
