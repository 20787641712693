import React from "react";
import FaqCard from "./FaqCard";
import { useLanguage } from "../../context/LanguageProvider";



const Faq = () => {
  const { language, texts } = useLanguage(); // Usamos useLanguage
  const questionList = [
    {
      question: texts[language].faqs[0],
      answer:
        texts[language].faqs[1],
    },
    {
      question: texts[language].faqs[2],
      answer:
        texts[language].faqs[3],
    },
    {
      question: texts[language].faqs[4],
      answer:
        texts[language].faqs[5],
    },
    {
      question: texts[language].faqs[6],
      answer:
        texts[language].faqs[7],
    },
    {
      question: texts[language].faqs[8],
      answer:
        texts[language].faqs[9],
    },
  ];
  return (
    <main className="container" id="faq">
      <h2 className="hero__title">{texts[language].faqsTitle}</h2>
      <p className="hero__text">
      {texts[language].faqsSubtitle}
      </p>
      <section className="faq__list">
        {questionList.map(({ question, answer }, index) => {
          return <FaqCard question={question} answer={answer} key={index} />;
        })}
      </section>
    </main>
  );
};

export default Faq;
