import React from "react";
import {
  FcMultipleDevices,
  FcRating,
  FcStatistics,
  FcSupport,
} from "react-icons/fc";
import { useLanguage } from "../../../context/LanguageProvider";


const Features = () => {
  const { language, texts } = useLanguage(); // Usamos useLanguage

  
  const elements = [
    {
      icon: <FcMultipleDevices />,
      title: texts[language].features.feature[0],
      description:
      texts[language].features.feature[1]
    },
    {
      icon: <FcRating />,
      title: texts[language].features.feature[2],
      description:
      texts[language].features.feature[3]
        },
    {
      icon: <FcStatistics />,
      title: texts[language].features.feature[4],
      description:
      texts[language].features.feature[5]
    },
    {
      icon: <FcSupport />,
      title: texts[language].features.feature[6],
      description:
      texts[language].features.feature[7]
        },
  ];
  return (
    <div id="features">
      <h2 className="features__title">
      {texts[language].features.title}
      </h2>
      <p className="features__copy">
      {texts[language].features.subTitle}
        
      </p>
      <div className="features__wrapper container">
        {elements.map(({ icon, title, description }, index) => {
          return (
            <div key={index} className="feature">
              {icon}
              <h3>{title}</h3>
              <p>{description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Features;
