import BaseFaqContainer from "../Base/BaseFaq";
import ContactForm from "../Home/ContactForm";
import Hero from "../Home/Hero";
import Warranty from "../Home/Warranty";
import Process from "../WebDev/Process";
import { useLanguage } from "../../context/LanguageProvider";




const AppDev = () => {
  const { language, texts } = useLanguage(); // Usamos useLanguage

  const faqs = [
    {
      "id": 1,
      "question": texts[language].appFaqs[0],
      "answer": texts[language].appFaqs[1]
    },
    {
      "id": 2,
      "question": texts[language].appFaqs[2],
      "answer": texts[language].appFaqs[3]
    },
    {
      "id": 3,
      "question": texts[language].appFaqs[4],
      "answer": texts[language].appFaqs[5]
    },
    {
      "id": 4,
      "question": texts[language].appFaqs[6],
      "answer": texts[language].appFaqs[7]
    },
    {
      "id": 5,
      "question": texts[language].appFaqs[8],
      "answer": texts[language].appFaqs[9]
    },
    {
      "id": 6,
      "question": texts[language].appFaqs[10],
      "answer": texts[language].appFaqs[11]
    }
  ]
  return (
    <main className="main">
      <Hero
        additionalClass="app-hero"
        title={texts[language].app.title}
        text={texts[language].app.subTitle}
        other={<a href="#contact" className="btn primary">{texts[language].app.callToAction}</a>}
      />
      <section className="post-hero">
        <p>
        {texts[language].app.hero[0]}<b>{texts[language].app.hero[1]}</b> {texts[language].app.hero[2]}
        </p>
        <p>{texts[language].app.hero[3]}</p>
        <div style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '1rem'}}>
          <a className="btn primary" href="#contact">{texts[language].app.callToActionHero}</a>
        </div>
      </section>
      <section className="services__software">
        <div className="column-61">
          <p>
          {texts[language].app.featuretteText[0]} <b>{texts[language].app.featuretteText[1]}</b>{" "}
          {texts[language].app.featuretteText[2]}{" "}
            <b>
            {texts[language].app.featuretteText[3]}
            </b>
          </p>
          <p>
          {texts[language].app.featuretteText[4]}{" "}
            <b> {texts[language].app.featuretteText[5]}</b>
            {texts[language].app.featuretteText[6]}{" "}
            <b> {texts[language].app.featuretteText[7]}</b>

          </p>
          <div>
                <button className="btn primary" onClick={() => window.location.hash = '#contact'}>{texts[language].app.callToActionHero}</button>
            </div>
        </div>
        <div className="column-39">
          <img src="/assets/soft-desc.png" alt="" />
        </div>
      </section>
      <BaseFaqContainer faqs={faqs}/>
      <Process />
      <Warranty />
      <ContactForm />
    </main>
  );
};

export default AppDev;
