import ProcessList from "./ProcessList/ProcessList"
import { useLanguage } from "../../../context/LanguageProvider";

const Process = () => {
    const { language, texts } = useLanguage(); // Usamos useLanguage

    return(
        <section className="process">
            <h2>{texts[language].process}</h2>
            <ProcessList />
            <div className="cta">
                <a className="btn primary" style={{textDecoration: 'none'} } href="#contact">{texts[language].web.callToAction}</a>
            </div>
        </section>
    )
}

export default Process;