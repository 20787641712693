import React, { createContext, useContext, useState } from 'react';

const texts = {
  en: {

    navbar :{
      services: "Services",
      faqs: "FAQs",
      contactUs: "Contact Us"
    },

    titleHome: "We transform ideas into solutions",
    subtitleHome: "Experts in software development",
    conoce: "Discover our",

    postHeroHomeOne:"COMMITTED TO HELPING YOU ACHIEVE YOUR ",
    postHeroHomeTwo:"GOALS",
    postHeroHomeThree:"CUSTOMIZED AND TOP-QUALITY ",
    postHeroHomeFour:"TECHNOLOGIES",

    servicios: "Services",
    servicesSubtitle:"Discover how our expertise in artificial intelligence and machine learning can revolutionize your digital business.",
    webDevelopment: "Web Development",
    webDevelopmentDescription:"From an online store to an educational site, whatever you need, we'll make it a reality.",
    appDevelopment: "App Development",
    appDevelopementDescription:"Using the latest technologies, your application will be cutting-edge.",
    softwareDevelopment: "Software Development",
    softwareDevelopementDescription:"We'll provide the perfect tool your business needs.",
  
    benefits:"BENEFITS",
    benefitOne:"GROWTH",
    descriptionBenefitOne:"Make use of the latest technologies to grow your business",

    benefitTwo:"PRESENCE",
    descriptionBenefitTwo:"Position your business with a modern and professional image",

    benefitThree:"COMMUNICATION",
    descriptionBenefitThree:"We keep an open channel to ensure that your needs are met throughout the entire process",

    benefitFour:"TEAM",
    descriptionBenefitFour:"With years of experience, our multidisciplinary teams focus from the beginning on the success of your project",

    benefitFive:"TAKE OFF",
    descriptionBenefitFive:"Our solutions will allow you to take off and achieve your business goals",

    warrantyOne: "All our products have ",
    warrantyTwo: "warranty",
    warrantyThree: "for technical failures.",

    contactUs: "Contact us",
    idea: "Do you have an idea for a project?",
    completeForm: "Complete the form and we will get in touch to help you turn your ideas into reality!",
    thanks: "Thank you for getting in touch!",
    willGetInTouch:"We will be in touch shortly!",
    howCanWeHelp:"How can we help you?",
    name:"Name",
    invalidEmail: "Enter a valid email",
    sending:"Sending...",
    send:"Send",
    us:"About Us",
    footerDescription:"We are a technology innovation company that creates high-quality solutions for our client's products and services. We are committed to building a lasting working relationship with our clients based on trust and mutual support.",
    workTogether:"Let's work together!",

    
      web: {
        title: "We Design Professional Websites",
        subTitle: "Elevate your brand with a tailored website.",
        callToAction: "Get in touch with us",
        hero: ["Present your company with a ", "Landing Page", "Sell commission-free with your ", "E-Commerce Store", "Share your knowledge with your ", "E-Learning Platform"],
        featuretteTitle: "Website",
        featuretteText: ["Developed to meet the needs of your company.","With our experience in web development, we create completely customized platforms for the client's business. We make tailor-made solutions for education, e-commerce, and services among others.","We will turn your ideas into digital realities that drive your online success. Our websites are fast, secure, and easy to manage."]
      },
      features: {
        title: "SOME FEATURES",
        subTitle: "We will help you find all the functionalities to make the software your perfect tool.",
        feature: ["100% Responsive.", "We work to make your site look good on PCs, tablets, and phones.", "Professional Image.", "We create sites that will allow you to stand out from your competition.", "Growth.", "We create effective calls to action to capture potential customers.", "Tailored.", "We develop sites according to your needs, we like challenges."]
      },
      webFaqs: [
        "What's a Landing Page and what is it for?"," A Landing Page is a website designed to convert visitors into leads or potential customers. It is effective for promoting products, services, or events, as it focuses on a specific offer with a clear call to action.",
        "How long will it take to develop my website?"," The development time varies depending on the complexity of the project. It can take from a few weeks to several months, considering design, functionality, content integration, and revisions.",
        "What does the design and development of my website include?"," It includes planning, visual design, coding, content integration, optimization for mobile devices and search engines. Also functionalities such as contact forms, integration of social networks, online stores, and content management systems.",
        "How does the process of designing and developing my website work?"," It follows steps such as: gathering requirements, visual design, development and coding, content integration, testing and review, launch and deployment. Constant communication and feedback between client and team are key.",
        "How can I optimize my website for search engines?"," SEO optimization involves using relevant keywords, well-written meta tags, friendly URLs, quality content, internal and external links, logical site structure, fast loading speed, and mobile compatibility.",
        "What advantages does the development of an e-commerce platform offer?"," The development of an e-commerce platform offers numerous advantages, such as the ability to reach a broad global market 24 hours a day, 7 days a week. It also provides a convenient and secure shopping experience for customers, integration of secure payment systems, real-time inventory tracking, product customization and promotions, as well as the ability to collect and analyze data to improve the user experience and increase sales."
      ],
      webSteps: [
        "You contact us through one of our contact methods.",
        "We meet with you to understand your needs.",
        "We design your project according to your needs, tastes, and preferences.",
        "Once you approve the design, we develop it.",
        "We deliver the solution completely optimized!"
      ],
      start: "Reach us",

      app:{
        title:"Mobile App Development.",
        subTitle:"We create cross-platform applications for iOS and Android.",
        callToAction:"Get in touch with us",
        hero : ["Do you have a ", "mobile app project", "in mind?","We develop it!"],
        callToActionHero:"Start the conversation",
        // featuretteTitle:"Website",
        featuretteText:["To succeed in the project, we","fully engage","from the beginning. We ensure to fully understand what your business needs and ","we take care of making this software your perfect tool.","All our developments are designed to be","scalable,"," so as your company grows,","the software can grow with you."]
      },
      
      appFaqs : [
        "What is a Landing Page and what is it used for?",
        "A Landing Page is a website designed to convert visitors into leads or potential customers. It's effective for promoting products, services, or events, as it focuses on a specific offer with a clear call to action.",
        "How long will it take to develop my website?",
        "The development time varies depending on the complexity of the project. It can take from a few weeks to several months, considering design, functionality, content integration, and revisions.",
        "What is included in the design and development of my website?",
        "It includes planning, visual design, coding, content integration, optimization for mobile devices and search engines. Also functionalities such as contact forms, social media integration, online stores, and content management systems.",
        "How does the design and development process of my website work?",
        "It follows steps such as: gathering requirements, visual design, development and coding, content integration, testing and review, launch and deployment. Constant communication and feedback between client and team are key.",
        "How can I optimize my website for search engines?",
        "SEO optimization involves using relevant keywords, well-written meta tags, friendly URLs, quality content, internal and external links, logical site structure, fast loading speed, and mobile compatibility.",
        "What advantages does the development of an e-commerce platform offer?",
        "The development of an e-commerce platform offers numerous advantages, such as the ability to reach a broad global market 24 hours a day, 7 days a week. It also provides a convenient and secure shopping experience for customers, integration of secure payment systems, real-time inventory tracking, product customization and promotions, as well as the ability to collect and analyze data to enhance user experience and increase sales."
      ],
      process: "The process",

      software:{
        title:"Software Development",
        subTitle:"Boost your market competitiveness with tailor-made tools.",
        callToAction:"Get in touch with us",
        hero : ["WITH A STRONG BACKGROUND, WE ARE READY TO HELP YOU REALIZE YOUR IDEAS BY DEVELOPING ", "CUSTOM SOFTWARE", "FOR YOUR ","NEEDS AND GOALS"],
      //   featuretteTitle:"Website",
      //   featuretteText:["Developed to meet your company's needs.","With our experience in web development, we create fully customized platforms for the client's business. We provide tailor-made solutions for education, e-commerce, and services, among others.","We'll turn your ideas into digital realities that drive your online success. Our websites are fast, secure, and easy to manage."]
        },
      
      
      softwareFaqs : [
        "What is custom software development?",
        "Custom software development refers to the creation of applications - of any type - specifically designed to meet the needs and objectives of a particular client. It is built from scratch, taking into account the unique requirements of the project.",
        "How long does it take to develop custom software?",
        "The development time for custom software varies depending on the complexity of the project, functionality requirements, and the size of the development team. It can take from a few months to over a year to complete.",
        "What is included in custom software development?",
        "Custom software development involves activities such as requirements analysis, architecture design, code development, quality testing, implementation, training, and ongoing support. It is tailored to the specific needs of the client.",
        "What is the process of custom software development?",
        "The process of custom software development generally follows stages such as requirements analysis, design, development, testing, implementation, and maintenance. It focuses on close collaboration with the client and adaptability to changes.",
        "How can I ensure the quality of my custom software?",
        "To ensure the quality of custom software, it is important to establish quality criteria from the beginning of the project, conduct thorough testing at each stage of development, incorporate client feedback, and make continuous improvements.",
        "What advantages does custom software development offer for my business?",
        "Custom software development provides advantages such as greater flexibility and customization, better integration with existing systems, increased security and scalability, as well as a better long-term return on investment by aligning the software with specific business goals."
      
      ],
      useCases: 'Use Cases',
      softwareCases: ["Custom Applications", "Process Automation", "Data Analysis and Visualization", "Service Integration - APIs", "Education - Online Learning", "Custom E-Commerce and Inventory"],

      faqsTitle : "Frequently Asked Questions",
      faqsSubtitle: "Have questions? We invite you to read the frequently asked questions about our services.",
      faqs : [
        "What payment methods do you accept at Argytec?",
        "You can pay with debit card, credit card, and cash through Paypal. We also accept wire transfers.",
        "Can you modify my website?",
        "Yes! We modify your existing website to make it look and function professionally and modernly.",
        "What is the process to have my website?",
        "The process we follow at Argytec begins with a meeting to understand the needs of each client. Then, a budget is prepared according to the tasks to be performed. Once accepted, we start developing your website.",
        "I'm not sure what type of website I need, can you advise me?",
        "Yes! Our team of experts will advise you to discover what type of solution you need to make the most of your space on the internet. Don't forget that we tailor products to your needs!",
        "I need another type of solution, can you help me to achieve it?",
        "At Argytec, we not only create websites, but we also provide all kinds of solutions. Our team has extensive knowledge in server development, databases, machine learning, among others. Contact us to carry out your projects!",
      ],      
    

  },
  es: {

    navbar :{
      services: "Servicios",
      faqs: "Preguntas Frecuentes",
      contactUs: "Contactanos"
    },
    titleHome: "Transformamos ideas en soluciones",
    subtitleHome: "Expertos en desarrollo de software",

    postHeroHomeOne:"COMPROMETIDOS EN AYUDARTE A ALCANZAR TUS",
    postHeroHomeTwo:"OBJETIVOS",
    postHeroHomeThree:"TECNOLOGÍAS PERSONALIZADAS Y DE",
    postHeroHomeFour:"CALIDAD SUPERIOR",

    servicios: "Servicios",
    servicesSubtitle:"Descubre cómo nuestra experiencia en inteligencia artificial y machine learning puede revolucionar tu negocio digital.",

    webDevelopment: "Desarrollo Web",
    webDevelopmentDescription:"Explorá las posibilidades de tu negocio con un sitio web moderno y profesional. Desde una tienda online, hasta un sitio educativo, lo que necesites, lo haremos realidad.",
    appDevelopment: "Desarrollo de Apps",
    appDevelopementDescription:"Utilizando las ultimas tecnologías tu aplicación será de vanguardia",
    softwareDevelopment: "Desarrollo de Software",
    softwareDevelopmentDescription:"Lo que necesites para tu negocio, lo haremos agregando valor con la experiencia de nuestros profesionales enfocados en que este software sea tu herramienta perfecta.",

    benefits:"BENEFICIOS",
    benefitOne:"CRECIMIENTO",
    descriptionBenefitOne:"Haz uso de las ultimas tecnologías para hacer crecer tu empresa",

    benefitTwo:"PRESENCIA",
    descriptionBenefitTwo:"Posiciona tu negocio con una imagen moderna y profesional",

    benefitThree:"COMUNICACIÓN",
    descriptionBenefitThree:"Mantenemos un canal  abierto para asegurar que sus necesidades sean satisfechas a lo largo de todo el proceso",

    benefitFour:"EQUIPO",
    descriptionBenefitFour:"Contando con años de experiencia, nuestros equipos multidiciplinarios se enfocan desde un inicio en el éxito de tu proyecto",

    benefitFive:"DEPEGUE",
    descriptionBenefitFive:"Nuestras soluciones que te permitirán despegar y alcanzar tus objetivos de negocio",

    warrantyOne: "Todos nuestros productos cuentan con",
    warrantyTwo: "garantía",
    warrantyThree: "por fallas técnicas.",

    contactUs: "Contactanos",

    conoce: "Conocé nuestros",
    idea: "¿Tenés una idea para un proyecto?",
    completeForm: "¡Completá el formulario y nos pondremos en contacto para ayudarte a convertir tus ideas en una realidad!",
    thanks: "Gracias por comunicarte!",
    willGetInTouch:"¡Nos pondremos en contacto a la brevedad!",
    name:"Nombre",
    howCanWeHelp:"¿Cómo podemos ayudarte?",
    invalidEmail: "Ingrese un email válido",
    sending:"Enviando...",
    send:"Enviar",
    us:"Nosotros",
    footerDescription:"Somos una compañía de innovación tecnológica, que crea soluciones para nuestros clientes productos y servicios de la más alta calidad. Estamos comprometidos con la construcción de una relación de trabajo duradera con nuestros clientes, basada en la confianza y el apoyo mutuo.",
    workTogether:"¡Trabajemos juntos!",


    web:{
      title:"Diseñamos sitios web profesionales",
      subTitle:"Elevá tu marca con un sitio web a medida.",
      callToAction:"Contactate con nosotros",
      hero : ["Presentá tu empresa con una ", "Landing Page", "Vendé sin comisiones con tu ","Tienda E-Commerce", "Transmití tus conocimientos con tu ","Plataforma E-Learning"],
      featuretteTitle:"Sitio Web",
      featuretteText:["Desarrollado a medida para cubrir las necesidades de tu empresa.","Con nuestra experiencia en desarrollo web crearamos plataformas completamente personalizadas para el negocio del cliente. Hacemos soluciones pensadas a medida, para educación, comercio electrónico y servicios entre otros.","Transformaremos tus ideas en realidades digitales que impulsen tu éxito en línea. Nuestros sitios web son rápidos, seguros y fáciles de administrar."]
    },

    features : {
      title: "ALGUNAS CARACTERISTICAS",
      subTitle: "Te ayudaremos a buscar todas las funcionalidades para hacer del software tu heramienta perfecta.",
      feature : ["Completamente responsive.","Trabajamos para que tu sitio se vea bien en monitores, tablets y celulares.","Imagen Profesional.","Realizamos sitios que te permitirán destacarte de tu competencia.","Crecimiento.","Creamos llamados a la acción eficaces para capturar clientes potenciales.","A Medida.","Desarrollamos sitios de acuerdo a tus necesidades, nos gustan los desafios."],
    },

    webFaqs : [
      "¿Qué es una Landing Page y para qué se utiliza?"," Una Landing Page es una página web diseñada para convertir visitantes en leads o clientes potenciales. Es efectiva para promocionar productos, servicios o eventos, ya que se centra en una oferta específica con un llamado a la acción claro.",
      "¿Cuánto tiempo tardará en desarrollarse mi sitio web?"," El tiempo de desarrollo varía según la complejidad del proyecto. Puede llevar desde unas semanas hasta varios meses, considerando diseño, funcionalidad, integración de contenido y revisiones.",
      "¿Qué incluye el diseño y desarrollo de mi sitio web?"," Incluye planificación, diseño visual, codificación, integración de contenido, optimización para dispositivos móviles y motores de búsqueda. También funcionalidades como formularios de contacto, integración de redes sociales, tiendas en línea y sistemas de gestión de contenido.",
      "¿Cómo funciona el proceso de diseño y desarrollo de mi sitio web?"," Sigue pasos como: recopilación de requisitos, diseño visual, desarrollo y codificación, integración de contenido, pruebas y revisión, lanzamiento y despliegue. La comunicación y retroalimentación constante entre cliente y equipo son clave.",
      "¿Cómo puedo optimizar mi sitio web para los motores de búsqueda?"," La optimización SEO implica usar palabras clave relevantes, metaetiquetas bien escritas, URLs amigables, contenido de calidad, enlaces internos y externos, estructura de sitio lógica, velocidad de carga rápida y compatibilidad móvil.",
      "¿Qué ventajas ofrece el desarrollo de una plataforma de e-commerce?"," El desarrollo de una plataforma de e-commerce ofrece numerosas ventajas, como la capacidad de llegar a un amplio mercado global las 24 horas del día, los 7 días de la semana. Además, proporciona una experiencia de compra conveniente y segura para los clientes, integración de sistemas de pago seguros, seguimiento de inventario en tiempo real, personalización de productos y promociones, así como la capacidad de recopilar y analizar datos para mejorar la experiencia del usuario y aumentar las ventas."
    ],

    webSteps:[
      "Te comunicas por alguno de nuestros medios de contacto.",
      "Nos reunimos con vos para conocer tus necesidades.",
      "Diseñamos tu pryecto de acuerdo a tus necesidades, gustos y preferencias.",
      "Una vez que apruebes el diseño, lo desarrollamos.",
      "¡Te entregamos la solución completamente optimizada!"
    ],

    start: "Inicia la comunicación",

    app:{
      title:"Desarrollo de Apps móviles.",
      subTitle:"Creamos aplicaciones multiplataforma para iOS y Android.",
      callToAction:"Contactate con nosotros",
      hero : ["¿Tenés un ", "proyecto de App móvil", "en mente?","¡Nosotros lo desarrollamos!"],
      callToActionHero:"Inicia la comunicación",
      // featuretteTitle:"Sitio Web",
      featuretteText:["Para tener éxito en el proyecto, nos","involucramos de lleno","desde un principio. Nos preocupamos por entender perfectamente lo que tu negocio necesita y ","nos encargamos de hacer de este software tu herramienta perfecta.","Todos nuestros desarrollos estan pensados para ser","escalables,"," para que a medida que crezca tu empresa,","el software pueda crecer con vos."]
    },

    appFaqs : [
      "¿Qué es una Landing Page y para qué se utiliza?",
      "Una Landing Page es una página web diseñada para convertir visitantes en leads o clientes potenciales. Es efectiva para promocionar productos, servicios o eventos, ya que se centra en una oferta específica con un llamado a la acción claro.",
      "¿Cuánto tiempo tardará en desarrollarse mi sitio web?",
      "El tiempo de desarrollo varía según la complejidad del proyecto. Puede llevar desde unas semanas hasta varios meses, considerando diseño, funcionalidad, integración de contenido y revisiones.",
      "¿Qué incluye el diseño y desarrollo de mi sitio web?",
      "Incluye planificación, diseño visual, codificación, integración de contenido, optimización para dispositivos móviles y motores de búsqueda. También funcionalidades como formularios de contacto, integración de redes sociales, tiendas en línea y sistemas de gestión de contenido.",
      "¿Cómo funciona el proceso de diseño y desarrollo de mi sitio web?",
      "Sigue pasos como: recopilación de requisitos, diseño visual, desarrollo y codificación, integración de contenido, pruebas y revisión, lanzamiento y despliegue. La comunicación y retroalimentación constante entre cliente y equipo son clave.",
      "¿Cómo puedo optimizar mi sitio web para los motores de búsqueda?",
      "La optimización SEO implica usar palabras clave relevantes, metaetiquetas bien escritas, URLs amigables, contenido de calidad, enlaces internos y externos, estructura de sitio lógica, velocidad de carga rápida y compatibilidad móvil.",
      "¿Qué ventajas ofrece el desarrollo de una plataforma de e-commerce?",
      "El desarrollo de una plataforma de e-commerce ofrece numerosas ventajas, como la capacidad de llegar a un amplio mercado global las 24 horas del día, los 7 días de la semana. Además, proporciona una experiencia de compra conveniente y segura para los clientes, integración de sistemas de pago seguros, seguimiento de inventario en tiempo real, personalización de productos y promociones, así como la capacidad de recopilar y analizar datos para mejorar la experiencia del usuario y aumentar las ventas."
    ],
    process: "El proceso",

    software:{
      title:"Desarrollo de software",
      subTitle:"Impulsamos tu competitividad en el mercado con herramientas hechas a medida.",
      callToAction:"Contactate con nosotros",
      hero : ["CON UN FUERTE BACKGROUND, ESTAMOS PREPARADOS PARA AYUDARTE A CONCRETAR TUS IDEAS DESARROLLANDO UN ", "SOFTWARE A MEDIDA", "DE TUS ","NECESIDADES Y OBJETIVOS"],
    //   featuretteTitle:"Sitio Web",
    //   featuretteText:["Desarrollado a medida para cubrir las necesidades de tu empresa.","Con nuestra experiencia en desarrollo web crearamos plataformas completamente personalizadas para el negocio del cliente. Hacemos soluciones pensadas a medida, para educación, comercio electrónico y servicios entre otros.","Transformaremos tus ideas en realidades digitales que impulsen tu éxito en línea. Nuestros sitios web son rápidos, seguros y fáciles de administrar."]
      },

    softwareFaqs : [
      "¿Qué es el desarrollo de software a medida?",
      "El desarrollo de software a medida se refiere a la creación de aplicaciones - de cualquier tipo - específicamente diseñadas para satisfacer las necesidades y objetivos de un cliente en particular. Se construye desde cero, teniendo en cuenta los requisitos únicos del proyecto.",
      "¿Cuánto tiempo tarda en desarrollarse un software a medida?",
      "El tiempo de desarrollo de un software a medida varía según la complejidad del proyecto, los requisitos de funcionalidad y el tamaño del equipo de desarrollo. Puede tomar desde unos pocos meses hasta más de un año para completarse.",
      "¿Qué incluye el desarrollo de software a medida?",
      "El desarrollo de software a medida implica actividades como análisis de requisitos, diseño de arquitectura, desarrollo de código, pruebas de calidad, implementación, capacitación y soporte continuo. Se adapta a las necesidades específicas del cliente.",
      "¿Cuál es el proceso de desarrollo de software a medida?",
      "El proceso de desarrollo de software a medida generalmente sigue etapas como análisis de requisitos, diseño, desarrollo, pruebas, implementación y mantenimiento. Se enfoca en la colaboración cercana con el cliente y la adaptabilidad a cambios.",
      "¿Cómo puedo garantizar la calidad de mi software a medida?",
      "Para garantizar la calidad de un software a medida, es importante establecer criterios de calidad desde el principio del proyecto, realizar pruebas exhaustivas en cada etapa del desarrollo, incorporar retroalimentación del cliente y realizar mejoras continuas.",
      "¿Qué ventajas ofrece el desarrollo de software a medida para mi negocio?",
      "El desarrollo de software a medida proporciona ventajas como mayor flexibilidad y personalización, mejor integración con sistemas existentes, mayor seguridad y escalabilidad, así como un mejor retorno de la inversión a largo plazo al alinear el software con los objetivos comerciales específicos."
    ],
    useCases: 'Casos de Uso',
    softwareCases: ["Aplicaciones Personalizadas", "Automatización de Procesos","Análisis y Visualización de Datos","Integración de Servicios - APIS","Educación - Online Learning","E-Commerce y Stock a Medida"],

    faqsTitle : "Preguntas Frecuentes",
    faqsSubtitle: "¿Tenés dudas? Te invitamos a leer las preguntas frecuentes sobre nuestros servicios.",
    faqs : [
      "¿Qué medios de pago aceptan en Argytec?",
      "Podrás abonar con tarjeta de débito, crédito y efectivo a través de MercadoPago. Además, aceptamos transferencias bancarias.",
      "¿Pueden modificar mi sitio web?",
      "¡Sí! Modificamos tu sitio web existente para que se vea y funcione de forma profesional y moderna.",
      "¿Cómo es el proceso para tener mi sitio web?",
      "El proceso que seguimos en Argytec comienza con una reunión para entender las necesidades de cada cliente. Luego se realiza un presupuesto acorde a las tareas a realizar. Una vez aceptado el mismo, comenzamos a desarrollar tu sitio web.",
      "No tengo en claro qué tipo de sitio web necesito, ¿Pueden asesorarme?",
      "¡Sí! Nuestro equipo de expertos te va a asesorar para descubrir qué tipo de solución necesitas para exprimir tu espacio en internet de la mejor manera. No olvides que realizamos productos a medida.",
      "Necesito otro tipo de solución, ¿Pueden ayudarme a realizarla?",
      "En Argytec no solo realizamos sitios web, sino que brindamos todo tipo de soluciones. Nuestro equipo tiene amplios conocimientos en desarrollo de servidores, bases de datos, machine learning, entre otros. ¡Contáctanos para realizar tus proyectos!",
    ],
    

  }
};

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en'); // idioma predeterminado en español

  return (
    <LanguageContext.Provider value={{ language, setLanguage, texts }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
