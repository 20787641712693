import { useLanguage } from "../../../context/LanguageProvider";

const Warranty = () => {
    const { language, texts } = useLanguage(); // Usamos useLanguage

    return (
        <section className="warranty">
            <h2>
            {texts[language].warrantyOne} <b>{texts[language].warrantyTwo}</b> {texts[language].warrantyThree}
            </h2>
            <img src={`http://${window.location.host}/assets/garantia.webp`} className="image" />
        </section>
    )
}

export default Warranty;