import BaseFaqContainer from "../Base/BaseFaq";
import ContactForm from "../Home/ContactForm";
import Hero from "../Home/Hero";
import Warranty from "../Home/Warranty";
import { useLanguage } from "../../context/LanguageProvider";


const SoftDev = () => {
  const { language, texts } = useLanguage(); // Usamos useLanguage

  const useCases = [
    {
      title: texts[language].softwareCases[0],
      image: "/assets/img_soft/apps.png",
    },
    {
      title: texts[language].softwareCases[1],
      image: "/assets/img_soft/procesos.png",
    },
    {
      title: texts[language].softwareCases[2],
      image: "/assets/img_soft/data.png",
    },
    {
      title: texts[language].softwareCases[3],
      image: "/assets/img_soft/integracion.png",
    },
    {
      title: texts[language].softwareCases[4],
      image: "/assets/img_soft/elearning.png",
    },
    {
      title: texts[language].softwareCases[5],
      image: "/assets/img_soft/ecommerce.png",
    },
  ];
  const faqs = [
    {
      "id": 1,
      "question": texts[language].softwareFaqs[0],
      "answer": texts[language].softwareFaqs[1]
    },
    {
      "id": 2,
      "question": texts[language].softwareFaqs[2],
      "answer": texts[language].softwareFaqs[3]
    },
    {
      "id": 3,
      "question": texts[language].softwareFaqs[4],
      "answer": texts[language].softwareFaqs[5]
    },
    {
      "id": 4,
      "question": texts[language].softwareFaqs[6],
      "answer": texts[language].softwareFaqs[7]
    },
    {
      "id": 5,
      "question": texts[language].softwareFaqs[8],
      "answer": texts[language].softwareFaqs[9]
    },
    {
      "id": 6,
      "question": texts[language].softwareFaqs[10],
      "answer": texts[language].softwareFaqs[11]
    }
  ]

  return (
    <main className="main">
      <Hero
        /* additionalClass="soft-hero" */
        title={texts[language].software.title}
        text={texts[language].software.subTitle}
        other={<a className="btn primary" href="#contact">{texts[language].app.callToActionHero}</a>}
      />
      <section className="post-hero">
        <p style={{ maxWidth: "800px", margin: "auto" }}>
        {texts[language].software.hero[0]} 
        <b>{texts[language].software.hero[1]} </b> {texts[language].software.hero[2]} {" "}
          <b>{texts[language].software.hero[3]} </b>
        </p>
      </section>
      <section className="use_cases">
          <h2>{texts[language].useCases}</h2>
        <div className="use_cases__container">
          {useCases.map((element, index) => {
            return (
              <div className="card" key={index}>
                  <img src={element.image} alt={element.title} />
                  <div className="card_body">
                    <p>{element.title}</p>
                  </div>
              </div>
            )
          })}
        </div>
        
      </section>
      <section className="services__software">
        <div className="column-61">
        <p>
          {texts[language].app.featuretteText[0]} <b>{texts[language].app.featuretteText[1]}</b>{" "}
          {texts[language].app.featuretteText[2]}{" "}
            <b>
            {texts[language].app.featuretteText[3]}
            </b>
          </p>
          <p>
          {texts[language].app.featuretteText[4]}{" "}
            <b> {texts[language].app.featuretteText[5]}</b>
            {texts[language].app.featuretteText[6]}{" "}
            <b> {texts[language].app.featuretteText[7]}</b>

          </p>
          <div>
                <button className="btn primary" onClick={() => window.location.hash = "#contact"}>{texts[language].app.callToActionHero}</button>
            </div>
        </div>
        <div className="column-39">
          <img src="/assets/soft-desc.png" alt="" />
        </div>
      </section>
      
      <BaseFaqContainer faqs={faqs}/>
      
      <Warranty />
      <ContactForm />
    </main>
  );
};

export default SoftDev;
