import Navbar from "./components/Navbar/Navbar";
import "./styles-v2/styles.scss";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/Helpers/ScrollToTop";
import Home from "./components-v2/Home";
import WebDev from "./components-v2/WebDev";
import AppDev from "./components-v2/AppDev";
import SoftDev from "./components-v2/SoftDev";
import Marketing from "./components-v2/Marketing";
import Design from "./components-v2/Design";
import Faq from "./components/Faq";
import RouterTransition from "./components/Helpers/RouterTransition";
import ReactGA from "react-ga4";
import { useEffect } from "react";

function AppNew() {
  
  useEffect(() => {
    ReactGA.initialize("G-V9ER5EV6SJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/argytec", title: "Landing Page" });
}, [])
  return (
    <BrowserRouter>
      <ScrollToTop>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/web" element={<WebDev />} />
            <Route exact path="/apps" element={<AppDev />} />
            <Route exact path="/software" element={<SoftDev />} />
            <Route exact path="/marketing" element={<Marketing />} />
            <Route exact path="/design" element={<Design />} />
            <Route exact path="/faq" element={<Faq />} />
          </Routes>
          <Footer />
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default AppNew;
