import React from "react";
import {BsArrowRightCircle} from 'react-icons/bs';
import { Link } from "react-router-dom";
import { useLanguage } from "../../../../context/LanguageProvider";
import ReactGA from "react-ga4";

const ServicesList = () => {
    const { language, texts } = useLanguage(); // Usamos useLanguage
    
    const services = [
    
        {
            title: texts[language].webDevelopment,
            shortDescription: texts[language].webDevelopmentDescription,
            detailRoute: '/web'
        },
        {
            title: texts[language].appDevelopment,
            shortDescription: texts[language].appDevelopementDescription,
            detailRoute: '/apps'
        },
        {
            title: texts[language].softwareDevelopment,
            shortDescription: texts[language].softwareDevelopementDescription,
            detailRoute: '/software'
        },
    ]

    const handleClick = (serviceTitle) => {
        ReactGA.event({
          category: 'Enlace de servicio',
          action: `Clic en enlace: ${serviceTitle}`,
        });
      };

    return(
        <div className="services_list">
            {services.map((service, index) => {
                return (
                    <Link to={service.detailRoute} 
                    key={index} 
                    className="services_list__item"
                    onClick={() => handleClick(service.title)} // Agrega el controlador de eventos aquí
                    >
                        <h3>{service.title}</h3>
                        <p>{service.shortDescription}</p>
                        <BsArrowRightCircle />
                    </Link>
                )
            })}
        </div>
    )
}

export default ServicesList;