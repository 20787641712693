import axios from "axios";
import React, { useState } from "react";
import { useLanguage } from "../../../context/LanguageProvider";

const ContactForm = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    subject: "",
  });
  const { language, texts } = useLanguage(); // Usamos useLanguage


  const [formError, setFormError] = useState(null);

  const [sending, setSending] = useState(false);

  const [mailSent, setMailSent] = useState(false);

  const regex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
  const fields = [
    { label: texts[language].name , name: "name", type: "text" },
    { label: "E-mail", name: "email", type: "email" },
    { label: texts[language].howCanWeHelp , name: "subject", type: "text" },
  ];

  const handleClick = (event) => {
    event.preventDefault();
    if (!regex.test(data.email)) {
      setFormError( texts[language].enterValidEmail);
    } else {
      setSending(true);
      axios({
        method: "post",
        url: "/api/contact/",
        headers: { "content-type": "application/json" },
        data: data,
      })
        .then((response) => {
          setSending(false);
          setMailSent(true);
        })
        .catch((error) => {
          setSending(false);
          setFormError(error.message);
        });
    }
  };
  const handleChange = (event) => {
    if (formError) {
      setFormError(null);
    }
    setData({ ...data, [event.target.name]: event.target.value });
  };
  if (mailSent) {
    return (
      <div
        style={{
          textAlign: "center",
          marginTop: "10rem",
          marginBottom: "10rem",
        }}
      >
        <h2 className="hero__title">{texts[language].thanks}</h2>
        <p className="hero__text">{texts[language].willGetInTouch}</p>
      </div>
    );
  } else {
    return (
      <section className="contact" id="contact">
        <h2>{texts[language].contactUs}</h2>
        <p style={{ marginBottom: "2rem", marginTop: 0, textAlign: "center" }}>
        {texts[language].idea} <br /> {texts[language].completeForm}
        </p>
        <form>
          {fields.map((field, index) => (
            <input
              key={index}
              type={field.type}
              name={field.name}
              placeholder={field.label}
              className="form-control"
              onChange={handleChange}
              required
            />
          ))}
          {formError ? <div style={{ color: "red" }}>{formError}</div> : null}
          <button
            className="btn primary"
            onClick={handleClick}
            disabled={!data.name || !data.email || !data.subject}
          >
            {sending ? texts[language].sending : texts[language].send}
          </button>
        </form>
      </section>
    );
  }
};

export default ContactForm;
