import BenefitsListItem from "./BenefitsListItem/BenefitsListItem"
import { useLanguage } from "../../../../context/LanguageProvider";

const BenefitsList = () => {
    const { language, texts } = useLanguage(); // Usamos useLanguage

const elements = [
    {
        image: `/assets/benef1.png`,
        title: texts[language].benefitOne,
        description: texts[language].descriptionBenefitOne
    },
    {
        image: `/assets/benef2.png`,
        title: texts[language].benefitTwo,
        description: texts[language].descriptionBenefitTwo
    },
    {
        image: `/assets/benef3.png`,
        title: texts[language].benefitThree,
        description: texts[language].descriptionBenefitThree
    },
    {
        image: `/assets/benef4.png`,
        title: texts[language].benefitFour,
        description: texts[language].descriptionBenefitFour
    },
    {
        image: `/assets/benef5.png`,
        title: texts[language].benefitFive,
        description: texts[language].descriptionBenefitFive
    },
]
    return (
        <ul className="benefits__list">
            {elements.map((element, index) => {
             return <BenefitsListItem key={index} benefit={element}/>
            })}
        </ul>
    )
}

export default BenefitsList;