import React from "react";
import { Link } from "react-router-dom";
import { FaInstagram, FaFacebook, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import {MdEmail, MdPhone} from "react-icons/md";
import { useLanguage } from "../../context/LanguageProvider";


const Footer = () => {
  const { language, texts } = useLanguage(); // Usamos useLanguage

  return (
    <footer>
      <div className="main_footer">
        <div className="about">
          <h2>{texts[language].us}</h2>
          <p>
            {texts[language].footerDescription}
               {" "}
          </p>
          <button className="btn primary"><a href='/#contact'>{texts[language].workTogether }</a></button>
        </div>
        <div className="footer_contact">
          <h2>{texts[language].contactUs }.</h2>
          <div className="footer_contact_data">
          <div><MdPhone/>+5491140790676</div>
          <div><MdEmail /><a href="mailto:argytec@gmail.com">info@argytec.com</a></div>
          <div><FaWhatsapp /><a href="https://wa.me/5491140790676">+54 9 1140790676</a></div>
          </div>
        <div className="social_media">
          <div className="icons">
            <a href="https://www.instagram.com/argytec">
              <FaInstagram />
            </a>
            
            <a href="https://www.linkedin.com/company/argytec">
              <FaLinkedin />
            </a>
          </div>
        </div>
        </div>

      </div>
      <div className="copyright">Copyright © {new Date().getFullYear()}, Argytec.</div>
    </footer>
  );
};

export default Footer;
