import Main from "./components/Main/index";
import Navbar from "./components/Navbar/Navbar";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ContactForm from "./components/Contact/Contact";
import Services from "./components/Services/Services";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/Helpers/ScrollToTop";
import Faq from "./components/Faq";
import NewServiceContainer from "./components/NewServiceComponent/NewServiceContainer";
import ServiceDetailContainer from "./components/NewServiceComponent/ServiceDetailContainer";
import { LanguageProvider } from "./context/LanguageProvider";
//<Route path="/services" element={<Services />} />
function App() {
  return (
    <LanguageProvider>
      <BrowserRouter>
        <ScrollToTop>
          <Navbar />
          <Routes>
            <Route index element={<Main />} />
            <Route path="/contact" element={<ContactForm />} />
            
            <Route path="/faq" element={<Faq />} />
            <Route path="/services/:slug" element={<ServiceDetailContainer />} />
            <Route path="/services" element={<NewServiceContainer />} />
          </Routes>
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    </LanguageProvider>
    
  );
}

export default App;
