import ProcessListItem from "./ProcessListItem/ProcessListItem"
import { useLanguage } from "../../../../context/LanguageProvider";


const ProcessList = () => {
    const { language, texts } = useLanguage(); // Usamos useLanguage

    const etapas = [
        {
            image: `/assets/contacto.png`,
            description: texts[language].webSteps[0]
        },
        {
            image: `/assets/reunion.png`,
            description: texts[language].webSteps[1]
        },
        {
            image: `/assets/diseno.png`,
            description: texts[language].webSteps[2]
        },
        {
            image: `/assets/desarrollo.png`,
            description: texts[language].webSteps[3]
        },
        {
            image: `/assets/entrega.png`,
            description: texts[language].webSteps[4]
        },
    ]
    
    return(
        <ul>
            {etapas.map((etapa, index) => {
                return(
                    <ProcessListItem etapa={etapa} key={index}/>
                )
            })}
        </ul>
    )
}

export default ProcessList