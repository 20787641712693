import BaseFaqContainer from "../Base/BaseFaq";
import ContactForm from "../Home/ContactForm";
import Hero from "../Home/Hero";
import Warranty from "../Home/Warranty";
import Features from "./Features/Features";
import Process from "./Process";
import { useLanguage } from "../../context/LanguageProvider";




;
const WebDev = () => {
  const { language, texts } = useLanguage(); // Usamos useLanguage

  const faqs = [
    {
      "id": 1,
      "question": texts[language].webFaqs[0],
      "answer": texts[language].webFaqs[1]
    },
    {
      "id": 2,
      "question": texts[language].webFaqs[2],
      "answer": texts[language].webFaqs[3]
    },
    {
      "id": 3,
      "question": texts[language].webFaqs[4],
      "answer": texts[language].webFaqs[5]
    },
    {
      "id": 4,
      "question": texts[language].webFaqs[6],
      "answer": texts[language].webFaqs[7]
    },
    {
      "id": 5,
      "question": texts[language].webFaqs[8],
      "answer": texts[language].webFaqs[9]
    },
    {
    "id": 6,
    "question": texts[language].webFaqs[10],
    "answer": texts[language].webFaqs[11]
  }
  
  ]
  return (
    <main className="main">
      <Hero
        additionalClass="web-hero"
        title={texts[language].web.title}
        text={texts[language].web.subTitle}
        other={<a className="btn primary" href="#contact">{texts[language].web.callToAction}</a>}
      />
      <section className="post-hero">
        <p>
        {texts[language].web.hero[0]}<b>{texts[language].web.hero[1]}</b>
        </p>
        <p>
          {texts[language].web.hero[2]}<b>{texts[language].web.hero[3]}</b>
        </p>
        <p>
        {texts[language].web.hero[4]}<b>{texts[language].web.hero[5]}</b>
        </p>
      </section>
      
      <section className="featurette">
      <h3>{texts[language].web.featuretteTitle}</h3>
      <div className="featurette__container">
        <div className="column-39">
            <img src="/assets/web.png" alt="website" className="img-fluid"/>
          </div>
          <div className="column-61">
            <p className="titulo">
            {texts[language].web.featuretteText[0]}
            </p>
            <p className="texto">
            {texts[language].web.featuretteText[1]}
            </p>
            <p className="texto">
            {texts[language].web.featuretteText[2]}
            
            </p>
          </div>
      </div>
       
      </section>
      <Features />
      <BaseFaqContainer faqs={faqs} />
      <Process />
      <Warranty />
      <ContactForm />
    </main>
  );
};

export default WebDev;
