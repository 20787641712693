import { useLanguage } from "../../../context/LanguageProvider";

const PostHero = () => {
    const { language, texts } = useLanguage(); // Usamos useLanguage

    return(
        <section className="post-hero">
            <p>{texts[language].postHeroHomeOne} <br/><b>{texts[language].postHeroHomeTwo}</b></p>
            <p>{texts[language].postHeroHomeThree} <br/><b>{texts[language].postHeroHomeFour}</b></p>
        </section>
    )
}

export default PostHero;