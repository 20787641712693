import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Burger from "./Burger";
import Logo from "../Logo/Logo";
import LanguageSwitcher from "../../context/LanguageSwitcher"; // Importa LanguageSwitcher
import { useLanguage } from "../../context/LanguageProvider";


const scrollWithOffset = (el, offset) => {
  const elementPosition = el.offsetTop - offset;
  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: "smooth",
  });
};

const Navbar = () => {
  const { language, texts } = useLanguage(); // Usamos useLanguage
  const [isActive, setIsActive] = useState(false);

  return (
    <header className="container" style={{ justifyContent: "space-between" }}>
      <Link to="/">
        <Logo />
      </Link>
      <nav>
        <Burger isActive={isActive} setIsActive={setIsActive} />
        <ul className={`menu container ${isActive ? "active" : ""}`}>
          <li>
            <Link to="/" onClick={() => setIsActive(false)}>
              Home
            </Link>
          </li>
          <li>
            <HashLink to="/#services" scroll={el => scrollWithOffset(el, 100)} onClick={() => setIsActive(false)}>
              {texts[language].navbar.services}
            </HashLink>
          </li>
          <li>
            <Link to="/faq" onClick={() => setIsActive(false)}>
            {texts[language].navbar.faqs}
            </Link>
          </li>
          <li>
            <HashLink to="/#contact" scroll={el => scrollWithOffset(el, 80)} onClick={() => setIsActive(false)}>
            {texts[language].navbar.contactUs}
            </HashLink>
          </li>
            <LanguageSwitcher toggleNavOpen={setIsActive} navOpen={isActive}/>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
