import React, { useState } from "react";
import { useLanguage } from "./LanguageProvider";
import { RiArrowDropDownLine } from "react-icons/ri";

function ArFlag() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <mask id="a">
        <circle cx="256" cy="256" r="256" fill="#fff" />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#338af3"
          d="M0 0h512v144.7L488 256l24 111.3V512H0V367.3L26 256 0 144.7z"
        />
        <path fill="#eee" d="M0 144.7h512v222.6H0z" />
        <path
          fill="#ffda44"
          d="m332.4 256-31.2 14.7 16.7 30.3-34-6.5-4.2 34.3-23.7-25.2-23.6 25.2-4.3-34.3-34 6.5 16.6-30.3-31.2-14.7 31.3-14.7L194 211l34 6.5 4.3-34.3 23.6 25.2 23.6-25.2 4.4 34.3 34-6.5-16.7 30.3z"
        />
      </g>
    </svg>
  );
}

function GbFlag() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <mask id="a">
        <circle cx="256" cy="256" r="256" fill="#fff" />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#eee"
          d="m0 0 8 22-8 23v23l32 54-32 54v32l32 48-32 48v32l32 54-32 54v68l22-8 23 8h23l54-32 54 32h32l48-32 48 32h32l54-32 54 32h68l-8-22 8-23v-23l-32-54 32-54v-32l-32-48 32-48v-32l-32-54 32-54V0l-22 8-23-8h-23l-54 32-54-32h-32l-48 32-48-32h-32l-54 32L68 0H0z"
        />
        <path
          fill="#0052b4"
          d="M336 0v108L444 0Zm176 68L404 176h108zM0 176h108L0 68ZM68 0l108 108V0Zm108 512V404L68 512ZM0 444l108-108H0Zm512-108H404l108 108Zm-68 176L336 404v108z"
        />
        <path
          fill="#d80027"
          d="M0 0v45l131 131h45L0 0zm208 0v208H0v96h208v208h96V304h208v-96H304V0h-96zm259 0L336 131v45L512 0h-45zM176 336 0 512h45l131-131v-45zm160 0 176 176v-45L381 336h-45z"
        />
      </g>
    </svg>
  );
}

const styles = {
  options: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "1fr 3fr",
    justifyContent: "center",
    flexDirection: "row",
    width: "100%",
    columnGap: ".5rem",
    marginBottom: "1rem",
    marginTop: "1rem",
    cursor: "pointer",
  },
};
function LanguageSwitcher({toggleNavOpen, navOpen}) {
  const { language, setLanguage } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    setIsOpen(false);
    if(navOpen) {
      toggleNavOpen(false)
    }
  };

  return (
    <div className="language-switcher" style={{ position: "relative" }}>
      <div
        style={{
          backgroundColor: "transparent",
          width: "3.5rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#f5f5f5",
          margin: "1rem",
          cursor: "pointer",
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div style={{ width: "80%" }}>
          {language === "en" ? <GbFlag /> : <ArFlag />}
        </div>
        <RiArrowDropDownLine size={50} />
      </div>
      <div
        id="language-switcher-dropdown"
        style={{
          width: "8rem",
          position: "absolute",
          backgroundColor: "rgba(5, 5, 5, 0.8)",
          paddingLeft: "12px",
          paddingRight: "12px",
          display: isOpen ? "block" : "none",
          transform: "translateX(-50%)",
          left: "50%",
          borderRadius: 5,
        }}
      >
        <div style={styles.options} onClick={() => handleLanguageChange("en")}>
          <div>
            <GbFlag />
          </div>{" "}
          <p style={{ color: "#f5f5f5" }}>English</p>
        </div>
        <div style={styles.options} onClick={() => handleLanguageChange("es")}>
          <div>
            <ArFlag />
          </div>{" "}
          <p style={{ color: "#f5f5f5" }}>Español</p>
        </div>
      </div>
    </div>
  );
}

export default LanguageSwitcher;
