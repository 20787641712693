import React from "react";
import { useLanguage } from "../../../context/LanguageProvider";

const Hero = ({title, text, other, additionalClass}) => {
    return(
        <section className={`hero ${additionalClass}`}>
            <h1 className="hero__title">{title? title :'Transformamos ideas en soluciones'}</h1>
            <h2 className="hero__text">{text ? text : 'Expertos en desarrollo de software'}</h2>
            {other}
        </section>
    )
}

export default Hero;