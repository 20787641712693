import BenefitsList from "./BenefitsList/BenefitsList"
import { useLanguage } from "../../../context/LanguageProvider";

const Benefits = () => {
    const { language, texts } = useLanguage(); // Usamos useLanguage

    return(
        <section className="benefits">
            <h2>{texts[language].benefits}</h2>
            <BenefitsList />
        </section>
    )
}

export default Benefits;