import ContactForm from "../Home/ContactForm";
import Hero from "../Home/Hero";
import Warranty from "../Home/Warranty";

const Marketing = () => {
  return (
    <main className="main">
      <Hero
        additionalClass="mkt-hero"
        title={"Marketing digital."}
        text={"Campañas de Google Ads y Redes Sociales"}
        other={<button className="btn primary">Contactate con nosotros</button>}
      />
      <section className="post-hero">
        <p>
          Aumentá <b>tus ventas</b> y potenciá tu negocio con <b>campañas publicitarias</b> eficientes.
        </p>
        <div
          style={{ marginLeft: "auto", marginRight: "auto", marginTop: "1rem" }}
        >
          <button className="btn primary">INICIA LA COMUNICACIÓN</button>
        </div>
      </section>
      <Warranty />
      <ContactForm />
    </main>
  );
};

export default Marketing;
