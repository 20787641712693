import ServicesContainer from "./ServicesContainer/ServicesContainer";
import { useRef } from "react";
import ServicesList from "./ServicesList/ServicesList";
import { useLanguage } from "../../../context/LanguageProvider";

const Services = () => {
  const container = useRef(null);
  const { language, texts } = useLanguage(); // Usamos useLanguage

  return (
    <section className="services" ref={container} id="services">
      <div className="services__header">
        {/* <h2>Conocé nuestros <span>Servicios.</span></h2> */}
        <h2>{texts[language].conoce} <span>{texts[language].servicios}.</span></h2>

        
        

        <div className="services__header__subtitulo">
        <p>
          {texts[language].servicesSubtitle}
        </p>
        </div>
      </div>
      {/* <ServicesContainer header={container} /> */}
      <ServicesList />
      {/* <article className="mt-4 services__software">
        <div className="column-61">
          <h2 className="mb-2">Software a medida</h2>
          <p>
            Construimos el software que tu negocio necesita. Para tener éxito en
            el proyecto, nos involucrarnos de lleno desde un principio. Nos
            preocupamos por entender perfectamente lo que tu negocio necesita y
            te ayudamos a buscar todas las funcionalidades para hacer del
            software tu heramienta perfecta. Todos nuestros desarrollos estan
            pensados para ser escalables, para que a medida que crezca tu
            empresa, el software pueda crecer con vos.
          </p>
          <button className="btn primary">Contactanos</button>
        </div>
        <div className="column-39"></div>
      </article> */}
    </section>
  );
};

export default Services;
