export default [
    {
        slug: 'landing',
        title: 'Landing Page',
        image: `http://${window.location.host}/assets/service_placeholder.png`,
        description: 'Especialmente diseñadas para aprovechar al máximo tus campañas publicitarias.',
        tiers: [
            {
                title: 'Basic',
                description: 'Sección de bienvenida, descripción de tus servicios, formulario de contacto, botón a WhatsApp, links a Redes Sociales y cuenta de e-mail profesional.'
            },
            {
                title: 'Medium',
                description: 'Basic Landing Page + Testimonio de tus clientes, sección de Preguntas Frecuentes, galería de fotos e integración con Google Analytics '
            },
            {
                title: 'PRO',
                description: 'Medium Landing Page + Diseño totalmente personalizable, con dos reuniones de revisión.'
            },
        ]
    },
    {
        slug: 'website',
        title: 'Sitio Web',
        image: `http://${window.location.host}/assets/service_placeholder.png`,
        description: 'Mejorá tu presencia en línea con un sitio web profesional.',
        tiers: [
            {
                title: 'Basic',
                description: 'Sección de bienvenida, página de tus productos o servicios, página de contacto, botón a WhatsApp, links a Redes Sociales y cuenta de e-mail profesional.'
            },
            {
                title: 'Medium',
                description: 'Sitio Web Basic + Testimonio de tus clientes, sección de Preguntas Frecuentes, galería de fotos e integración con Google Analytics '
            },
            {
                title: 'PRO',
                description: 'Sitio Web Medium + Diseño totalmente personalizable, con dos reuniones de revisión.'
            },
        ]
    },
    {
        slug: 'ecommerce',
        title: 'E-Commerce',
        image: `http://${window.location.host}/assets/service_placeholder.png`,
        description: 'Vendé en línea en tu sitio web profesional, sin comisiones por venta.',
        tiers: [
            {
                title: 'Basic',
                description: 'Sección de bienvenida, descripción de tus servicios, formulario de contacto, botón a WhatsApp, links a Redes Sociales y cuenta de e-mail profesional.'
            },
            {
                title: 'Medium',
                description: 'E-Commerce Basic + Testimonio de tus clientes, sección de Preguntas Frecuentes, galería de fotos e integración con Google Analytics '
            },
            {
                title: 'PRO',
                description: 'E-Commerce Medium + Diseño totalmente personalizable, con dos reuniones de revisión.'
            },
        ]
    },
]