const BenefitsListItem = ({benefit}) => {
    return(
        <li className="benefits__list__item">
            <div className="image_wrapper">
                <img src={benefit.image}/>
            </div>
            <h3>{benefit.title}</h3>
            <p>{benefit.description}</p>
        </li>
    )
}
export default BenefitsListItem;