import ContactForm from "../Home/ContactForm";
import Hero from "../Home/Hero";
import Warranty from "../Home/Warranty";

const Design = () => {
  return (
    <main className="main">
      <Hero
        additionalClass="design-hero"
        title={"Diseño Gráfico."}
        text={"Creamos tu imagen de marca."}
        other={<button className="btn primary">Contactate con nosotros</button>}
      />
      <section className="post-hero">
        <p>
          Materializá <b>tu marca</b> y presentala al mundo de la mejor manera posible.
        </p>
        <div
          style={{ marginLeft: "auto", marginRight: "auto", marginTop: "1rem" }}
        >
          <button className="btn primary">INICIA LA COMUNICACIÓN</button>
        </div>
      </section>
      <Warranty />
      <ContactForm />
    </main>
  );
};

export default Design;
